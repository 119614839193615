import Vue from 'vue'
import { logger } from '@/utils/logger'
import debounce from 'lodash/debounce'

// TODO: we can separate a lot of these out into their own mixins (imageMixin, formatMixin, fbMixin, etc)
export const mixins = Vue.extend({
    methods: {
        toFormattedUSD(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        scrollToTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
        },
        checkImageSize: function (imageBlob: any, minWidth: number, minHeight: number, cb: Function) {
            const img = new Image()
            img.onload = function () {
                cb((img.width >= minWidth && img.height >= minHeight) || (img.width >= minHeight && img.height >= minWidth))
            }
            img.src = imageBlob
        },
        compressImage: async function (imageBlob: any) {
            const startTime = performance.now()
            const img = new Image()
            img.src = imageBlob
            const canvas = document.createElement('canvas')

            const imgWidth = img.width
            const imgHeight = img.height
            // resize the canvas and draw the image data into it
            canvas.width = imgWidth
            canvas.height = imgHeight
            const ctx = canvas.getContext('2d')
            if (!ctx) {
                logger.warn('There is no context to draw on')
                return
            }
            ctx.drawImage(img, 0, 0, imgWidth, imgHeight)

            const imgBase64 = canvas.toDataURL('image/jpeg')
            const fileSizeMb = this.getFileSizeMb(imgBase64)
            logger.info(`image converted to jpg -  width: ${imgWidth}, height: ${imgHeight}, fileSize: ${fileSizeMb}Mb`)

            if (fileSizeMb < 1) {
                logger.info(`compressImage (no resize) execution time: ${Math.round(((performance.now() - startTime) * 100) / 100)}ms`)
                return imgBase64
            }

            // https://cloud.google.com/vision/docs/supported-files
            // Resize to Google vision recommended size: 1024 x 768
            const maxWidth = 1024
            const maxHeight = 768

            let ratio1 = 1,
                ratio2 = 1
            ratio1 = maxWidth / imgWidth
            ratio2 = maxHeight / imgHeight

            // Use the smallest ratio that the image best fit into the maxWidth x maxHeight box.
            const ratio = ratio1 < ratio2 ? ratio1 : ratio2

            const resizedWidth = imgWidth * ratio
            const resizedHeight = imgHeight * ratio

            // Create a canvas with resized image
            const resizedElement = document.createElement('canvas')
            resizedElement.width = resizedWidth
            resizedElement.height = resizedHeight
            // `getContext` shouldn't return null for this canvas since it was just created:
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/getContext#return_value
            const resizedCtx = resizedElement.getContext('2d')!
            if (!resizedCtx) {
                logger.warn('There is no context to draw on')
                return
            }
            resizedCtx.drawImage(img, 0, 0, resizedWidth, resizedHeight)

            // Reduce image quality to 80%
            const resizedBase64Image = resizedElement.toDataURL('image/jpeg', 0.8)
            const resizedFileSizeMb = this.getFileSizeMb(resizedBase64Image)
            logger.info(`resized jpg image (80% quality) - width: ${resizedWidth}, height: ${resizedHeight}, fileSize: ${resizedFileSizeMb}Mb, resize ratio: ${Math.round(ratio * 100) / 100}`)
            logger.info(`compressImage (resize) execution time: ${Math.round(((performance.now() - startTime) * 100) / 100)}ms`)
            return resizedBase64Image
        },
        getFileSizeMb: function (imgBase64: string) {
            // Calculate file size - the file size is 75% of the size of the base64 string
            const base64Length = imgBase64.length - (imgBase64.indexOf(',') + 1) // excludes start of string: "data:image/jpeg;base64,"
            const fileSizeBytes = base64Length * 0.75
            const fileSizeMb = Math.round((fileSizeBytes / Math.pow(1024, 2)) * 100) / 100
            return fileSizeMb
        },
        debounceEventLogging: debounce((eventName: string, metadata: object) => {
            window.logEvent(eventName, metadata)
        }, 500),
    },
})
