import { logger } from '@/utils/logger'
import { plaidReportFetchState, startPlaidReportFetch } from '@/services/api'

const PLAID_SRC = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'

type OnPlaidSuccess = (plaidPublicToken: string, accounts: object[], institutionInfo: object | any) => {}
type OnNoDepositoryAccountFound = (bankName: string) => {}
type OnPlaidExit = (error: any, metadata: object) => {}

export class PlaidManager {
    private plaidHandler: { open: () => void; exit: () => void } | undefined
    private readonly onPlaidSuccess: OnPlaidSuccess
    private onNoDepositoryAccountFound: OnNoDepositoryAccountFound
    private onPlaidExit: OnPlaidExit

    constructor(onPlaidSuccess: OnPlaidSuccess, onNoDepositoryAccountFound: OnNoDepositoryAccountFound, onPlaidExit: OnPlaidExit) {
        this.onPlaidSuccess = onPlaidSuccess
        this.onNoDepositoryAccountFound = onNoDepositoryAccountFound
        this.onPlaidExit = onPlaidExit
    }

    public init = async (): Promise<boolean> => {
        logger.info(`bankConnect headless`)
        // Load the Plaid script async
        await this.loadPlaidScript()
        return this.setupPlaid()
    }

    private setupPlaid = (): boolean => {
        try {
            this.plaidHandler = this.createPlaidHander(this.onPlaidSuccess, this.onNoDepositoryAccountFound, this.onPlaidExit)
            logger.info('Plaid handler initialized')
            return true
        } catch (error) {
            logger.error('Plaid initialize failed due to ad blocker, error:', null /* event */, error)
        }
        return false
    }

    public open = (): void => {
        logger.info('Opening plaid...')
        if (this.plaidHandler?.open) {
            this.plaidHandler.open()
        }
    }

    public exit = (): void => {
        logger.info('Making sure to exit plaid if its open...')
        this.plaidHandler?.exit()
    }

    private createPlaidHander = (onSuccess: OnPlaidSuccess, onNoDepositoryAccountFound: OnNoDepositoryAccountFound, onExit: OnPlaidExit) => {
        return window.Plaid.create({
            clientName: 'Aven',
            countryCodes: ['US'],
            env: process.env.VUE_APP_PLAID_ENV,
            key: process.env.VUE_APP_PLAID_PUBLIC_KEY,
            product: ['assets'],
            onEvent: function (eventName: string, metadata: object) {
                // log all events provided by plaid. note that these won't trigger until the plaid screen is closed.
                const internalEventName = `plaid_internal_${eventName.toLowerCase()}`
                window.logEvent(internalEventName, metadata)
            },
            onSuccess: function (public_token: string, metadata: any) {
                logger.info(`Plaid Bank: ${JSON.stringify(metadata.institution)}`)
                logger.info(`Plaid Bank accounts: ${JSON.stringify(metadata.accounts)}`)
                window.logEvent('plaid_on_success', { ['plaid_bank_name']: metadata.institution.name })

                const depositoryAccounts = metadata.accounts.filter((account: { subtype: string }) => {
                    return account.subtype?.toLowerCase() === 'checking' || account.subtype?.toLowerCase() === 'savings'
                })

                if (depositoryAccounts.length > 0) {
                    onSuccess(public_token, depositoryAccounts, JSON.stringify(metadata.institution as object))
                } else {
                    window.logEvent('error_message', { message: 'no_depository_account_found' })
                    onNoDepositoryAccountFound(metadata.institution.name)
                }
            },
            onExit: function (error: any, metadata: object) {
                if (error) {
                    logger.info(`plaid link onExit returned error: ${JSON.stringify(error)}`)
                    window.logEvent('plaid_internal_error', { ...error, ...metadata })
                }
                if (onExit) {
                    onExit(error, metadata)
                }
                logger.info(`plaid link onExit metaData: ${JSON.stringify(metadata)}`)
            },
        })
    }

    private loadPlaidScript = async (): Promise<void> => {
        for (const x of document.head.getElementsByTagName('script')) {
            if (x.src === PLAID_SRC) {
                logger.info('Plaid already loaded')
                return
            }
        }
        logger.info('Plaid script not found in DOM. Attaching...')

        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.onload = () => {
                logger.info('Plaid script loaded')
                resolve()
            }
            script.setAttribute('src', PLAID_SRC)
            logger.info('Waiting for Plaid script to load...')
            document.head.appendChild(script)
        })
    }

    public completePlaidFetch = async (plaidPublicToken: string, accounts: { id: string }[], institutionInfo: string, isCoApplicant?: boolean): Promise<boolean> => {
        const response = await startPlaidReportFetch(plaidPublicToken, accounts[0].id, institutionInfo, isCoApplicant)
        logger.info(`startPlaidReportFetch response.data: ${JSON.stringify(response.data)}`)

        if (response.data.success) {
            logger.info(`submitted bank account`)
            return await this.pollForPlaidReportCompletion(isCoApplicant)
        } else {
            logger.error(`failed to start plaid fetch`)
        }
        return false
    }

    private pollForPlaidReportCompletion = async (isCoApplicant?: boolean): Promise<boolean> => {
        let retryCount = 0
        logger.info(`Beginning to poll for plaidReport fetch completion`)
        while (retryCount < 150) {
            // ~ 2000ms * 150 retries = poll for 5 min
            const plaidReportState = await plaidReportFetchState(isCoApplicant)

            if (plaidReportState.data.payload.plaidReportState !== 'complete') {
                if (plaidReportState.data.payload.isFetched) {
                    break
                }
                retryCount++
                logger.info(`PlaidReport NOT READY, attempt#: ${retryCount}`)
                await new Promise((r) => setTimeout(r, 2000))
                continue
            }
            logger.info(`PlaidReport fetch is complete!`)
            return true
        }
        logger.warn(`PlaidReport fetch did not appear to complete successfully!`)
        return false
    }
}
